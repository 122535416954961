import React, { memo, useEffect, useState, useRef } from 'react'
import useApi from 'hooks/useApi'
import { getAllPaymentGateways } from 'actions/paymentGateway'
import { paymentGateways } from 'config/app'
import Gateway from './gateway'
import { payBooking, getBooking } from 'actions/booking'
import { useLoading } from 'hooks/useLoading'
import { useSnackbar } from 'notistack'
import Form from './Form'
import Dialog from './dialog'
import Timer from './timer'

const PaymentBooking = memo(({ bookingId, search, auto = true }) => {
  const [gateways, booking] = useApi(() => Promise.all([getAllPaymentGateways({}, true), getBooking(bookingId)]))
  const [, setLoading] = useLoading()
  const [form, setForm] = useState()
  const [message, setMessage] = useState()
  const dialogRef = useRef()
  const [additionalGateways, setAdditionalGateways] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const searchParams = new URLSearchParams(search)

  const onSelect = async (gateway, method) => {
    try {
      setLoading(true)
      const res = await payBooking(bookingId, searchParams.toString(), gateway, method)
      if (res.data?.message) {
        setMessage(res.data?.message)
        dialogRef.current.open()
      }      
      if (res.data?.form) {
        setForm(res.data.form)
      } else if (res.data?.url) {
        window.location = res.data?.url
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (searchParams.has('ios') && additionalGateways.length === 0) {
      setAdditionalGateways(['clinic'])
    }
  }, [searchParams, additionalGateways])

  // useEffect(() => {
  //   if (auto && gateways && gateways?.data.length === 1 && !gateways?.data.methods) {
  //     onSelect(gateways?.data.shift().id)
  //   }
  // }, [gateways])

  return (
    <>
      <Timer booking={booking} />
      {paymentGateways
        .filter((pg) => gateways?.data.find((g) => g.id === pg))
        .map((pg) => (
          <Gateway key={pg} gateway={pg} config={gateways?.data.find((g) => g.id === pg)} onClick={onSelect} />
        ))}
      {additionalGateways.map((pg) => (
        <Gateway key={pg} gateway={pg} config={{ conf: {} }} onClick={() => onSelect(pg)} />
      ))}
      {form && <Form form={form} />}
      <Dialog ref={dialogRef} message={message} />
    </>
  )
})

export default PaymentBooking
