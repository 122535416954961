import BaseModel from 'models/BaseModel'
import gatways from 'constants/paymentGateways'

class PaymentGatewayModel extends BaseModel {
  constructor(conf = null, deletedAt = null, id = null, masked = false) {
    super()
    this.conf = conf
    this.deletedAt = deletedAt
    this.label = gatways.find((g) => g.id == id)?.name
    this.masked = masked
    this.id = id
  }
}

export default PaymentGatewayModel
