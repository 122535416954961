import getCollection from './getCollection'
import updatePaymentGateway from './updatePaymentGateway'
import updateDataById from 'actions/base/updateDataById'

const unDeletePaymentGateway = async (id, paymentGateway) => {
  paymentGateway.deletedAt = null
  return updateDataById(id, getCollection, paymentGateway)
}

export default unDeletePaymentGateway
